/**
 * @file Manage all the modale functionalities
*/

const closeModaleKeys = ['escape'];

const modale = function() {
    // _log("modale");
    return typeof document == "undefined" ? null : getById('modale');
}

const modaleContainer = function() {
    // _log("modaleContainer");
    return typeof document == "undefined" ? null : getById('modale-container');
}

const modaleContent = function() {
    // _log("modaleContent");
    return typeof document == "undefined" ? null : getById('modale-content');
}

const modaleClose = function() {
    // _log("modaleClose");
    return typeof document == "undefined" ? null : getById('modale-close');
}

const openModale =  function(params) {
    // _log("openModale");
    // _log(params);
    _log(params);
    modale() != null && modale()?.classList.add('opened');
    params.modaleClass != undefined && modale() && (Array.isArray(params.modaleClass) ? modale()?.classList.add(...params.modaleClass) : modale()?.classList.add(params.modaleClass));
    params.containerClass != undefined && modaleContainer() && (Array.isArray(params.containerClass) ? modaleContainer()?.classList.add(...params.containerClass) : modaleContainer()?.classList.add(params.containerClass));
    params.contentClass != undefined && modaleContent() && (Array.isArray(params.contentClass) ? modaleContent()?.classList.add(...params.contentClass) : modaleContent()?.classList.add(params.contentClass));
    if(modaleContent() != null){
        modaleContent().innerHTML = closeModalePart() + params.content;
    }
    else {
        return false;
    }
    typeof params.onOpen == 'function' && params.onOpen();
    modale() != null && modale()?.addEventListener('click', function(e) {
        if(e.target.getAttribute('id') == 'modale') {
            closeModale(params);
        }
    });
    modale() != null && document.addEventListener('keyup', function(e) {
        if(!Array.isArray(closeModaleKeys)) {return false;}
        let done = false;
        closeModaleKeys.forEach(key => {
            if(!isString(key)) {return false;}
            if(e.key && e.key.toLowerCase() == key.toLowerCase() && !done) {
                closeModale(params);
                done = true;
            }
        });
    });
    modaleClose() != null && modaleClose()?.addEventListener('click', function(e) {
        closeModale(params);
    });
    return true;
};

const closeModale =  function(params = {}) {
    // _log("closeModale");
    try {
        if(params == null) {throw false;}
        typeof params.onClose == "function" && params.onClose();
        modale() != null && modale()?.classList.remove('opened');
        modaleContainer() != null && modaleContainer()?.classList.remove(...Array.from(modaleContainer()?.classList));
        if(modaleContent() != null) {
            modaleContent().innerHTML = "";
        }
        params.modaleClass != undefined && (Array.isArray(params.modaleClass) ? modale()?.classList.remove(...params.modaleClass) : modale()?.classList.remove(params.modaleClass));
        params.containerClass != undefined && (Array.isArray(params.containerClass) ? modaleContainer()?.classList.remove(...params.containerClass) : modaleContainer()?.classList.remove(params.containerClass));
        params.contentClass != undefined && (Array.isArray(params.contentClass) ? modaleContent()?.classList.remove(...params.contentClass) : modaleContent()?.classList.remove(params.contentClass));
    }
    catch(error) {
        _trace(error);
        _error(error);
    }
};

const closeModalePart = function() {
    // _log("closeModalePart");
    return `
        <span id="modale-close">
            <img src="/assets/icons/red-x-circle.svg" alt="close"/>
        </span>
    `;
}
